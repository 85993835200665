<template>
  <TTView>
    <TTExample
      file="components/TTAlert/examples/TTAlert.success"
    />

    <TTExample
      file="components/TTAlert/examples/TTAlert.info"
    />

    <TTExample
      file="components/TTAlert/examples/TTAlert.warning"
    />

    <TTExample
      file="components/TTAlert/examples/TTAlert.error"
    />
  </TTView>
</template>

<script>

export default {
  name: 'Alert',
  data() {
    return {};
  },
};
</script>
